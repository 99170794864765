 export const convert = {
    location:
        {
        
    stationContributions:null,
    
    values:[
    
        {wdir:0,uvindex:0.0,icon:'--',datetimeStr:"2022-07-25T15:00:00+01:00",preciptype:"",cin:-6.0,cloudcover:85.0,pop:9.5,datetime:1658707200000,precip:0.0,solarradiation:0.0,dew:70.4, humidity:77.2,temp:0,visibility:15.0,wspd:8.3,severerisk:10.0,solarenergy:0.0,heatindex:null,snowdepth:0.0,sealevelpressure:1014.0, snow:0.0,wgust:12.1,conditions:"Overcast",windchill:null,cape:202.0}, 
        {wdir:0,uvindex:0.0,icon:'--',datetimeStr:"2022-07-25T01:00:00+01:00",preciptype:"",cin:-10.0,cloudcover:98.6,pop:9.5,datetime:1658710800000,precip:0.0,solarradiation:0.0,dew:70.2, humidity:77.6,temp:0,visibility:15.0,wspd:8.5,severerisk:10.0,solarenergy:0.0,heatindex:null,snowdepth:0.0,sealevelpressure:1014.0, snow:0.0,wgust:12.5,conditions:"Overcast",windchill:null,cape:186.0}, 
        {wdir:0,uvindex:0.0,icon:'--',datetimeStr:"2022-07-25T02:00:00+01:00",preciptype:"",cin:-18.0,cloudcover:100.0,pop:28.6,datetime:1658714400000,precip:0.0, solarradiation:0.0,dew:70.2,humidity:78.1,temp:0,visibility:15.0,wspd:8.5,severerisk:10.0,solarenergy:0.0,heatindex:null,snowdepth:0.0,sealevelpressure:1013.0, snow:0.0,wgust:12.8,conditions:"Overcast",windchill:null,cape:166.0}, 
        {wdir:0,uvindex:0.0,icon:'--',datetimeStr:"2022-07-25T03:00:00+01:00",preciptype:"",cin:-22.0,cloudcover:100.0,pop:28.6,datetime:1658718000000,precip:0.0,solarradiation:0.0, dew:70.1,humidity:78.5,temp:0,visibility:15.0,wspd:8.3,severerisk:10.0,solarenergy:0.0,heatindex:null,snowdepth:0.0,sealevelpressure:1013.0,snow:0.0,wgust:12.8, conditions:"Overcast",windchill:null,cape:122.0}, 
        {wdir:0,uvindex:0.0,icon:'--',datetimeStr:"2022-07-25T04:00:00+01:00",preciptype:"",cin:-28.0,cloudcover:100.0,pop:28.6,datetime:1658721600000,precip:0.0,solarradiation:0.0, dew:69.9,humidity:78.5,temp:0,visibility:15.0,wspd:7.6,severerisk:10.0,solarenergy:0.0,heatindex:null,snowdepth:0.0,sealevelpressure:1013.0,snow:0.0, wgust:12.5,conditions:"Overcast",windchill:null,cape:104.0}, 
        {wdir:0,uvindex:0.0,icon:'--',datetimeStr:"2022-07-25T05:00:00+01:00",preciptype:"",cin:-21.0,cloudcover:100.0,pop:28.6,datetime:1658725200000,precip:0.0,solarradiation:0.0, dew:69.7,humidity:78.0,temp:0,visibility:15.0,wspd:7.2,severerisk:10.0,solarenergy:0.0,heatindex:null,snowdepth:0.0,sealevelpressure:1013.0,snow:0.0,wgust:12.1, conditions:"Overcast",windchill:null,cape:88.0}, 
        {wdir:0,uvindex:0.0,icon:'--',datetimeStr:"2022-07-25T06:00:00+01:00",preciptype:"",cin:-19.0,cloudcover:100.0,pop:28.6,datetime:1658728800000,precip:0.01,solarradiation:0.0, dew:69.9,humidity:80.4,temp:0,visibility:9.6,wspd:7.4,severerisk:10.0,solarenergy:0.0,heatindex:null,snowdepth:0.0,sealevelpressure:1013.0,snow:0.0,wgust:12.5, conditions:"Overcast",windchill:null,cape:69.0},
        {wdir:0,uvindex:0.0,icon:'--',datetimeStr:"2022-07-25T07:00:00+01:00",preciptype:"",cin:-17.0,cloudcover:100.0,pop:28.6,datetime:1658732400000,precip:0.02,solarradiation:0.0, dew:70.2,humidity:83.9,temp:0,visibility:14.1,wspd:7.4,severerisk:10.0,solarenergy:0.0,heatindex:null,snowdepth:0.0,sealevelpressure:1014.0,snow:0.0,wgust:13.4, conditions:"Overcast",windchill:null,cape:62.0}, 
        {wdir:0,uvindex:0.0,icon:'--',datetimeStr:"2022-07-25T08:00:00+01:00",preciptype:"",cin:-8.0,cloudcover:100.0,pop:28.6,datetime:1658736000000,precip:0.01,solarradiation:15.0, dew:70.4,humidity:84.4,temp:0,visibility:15.0,wspd:7.8,severerisk:10.0,solarenergy:0.1,heatindex:null,snowdepth:0.0,sealevelpressure:1014.0,snow:0.0,wgust:13.6, conditions:"Overcast",windchill:null,cape:76.0}, 
        {wdir:0,uvindex:2.0,icon:'--',datetimeStr:"2022-07-25T09:00:00+01:00",preciptype:"",cin:-27.0,cloudcover:100.0,pop:28.6,datetime:1658739600000,precip:0.0,solarradiation:213.0, dew:70.1,humidity:77.6,temp:0,visibility:15.0,wspd:8.3,severerisk:10.0,solarenergy:0.8,heatindex:null,snowdepth:0.0,sealevelpressure:1015.0,snow:0.0,wgust:11.4, conditions:"Overcast",windchill:null,cape:89.0},
        {wdir:0,uvindex:1.0,icon:'--',datetimeStr:"2022-07-25T10:00:00+01:00",preciptype:"",cin:-58.0,cloudcover:99.1,pop:28.6,datetime:1658743200000,precip:0.0,solarradiation:92.0, dew:69.7,humidity:77.1,temp:0,visibility:15.0,wspd:8.5,severerisk:10.0,solarenergy:0.3,heatindex:null,snowdepth:0.0,sealevelpressure:1015.0,snow:0.0,wgust:11.2, conditions:"Overcast",windchill:null,cape:56.0}, 
        {wdir:0,uvindex:5.0,icon:'--',datetimeStr:"2022-07-25T11:00:00+01:00",preciptype:"",cin:-52.0,cloudcover:98.6,pop:28.6,datetime:1658746800000,precip:0.0,solarradiation:495.0, dew:69.2,humidity:69.3,temp:0,visibility:15.0,wspd:9.2,severerisk:10.0,solarenergy:1.8,heatindex:83.1,snowdepth:0.0,sealevelpressure:1015.0,snow:0.0,wgust:9.4, conditions:"Overcast",windchill:null,cape:47.0},
        {wdir:0,uvindex:8.0,icon:'--',datetimeStr:"2022-07-25T12:00:00+01:00",preciptype:"",cin:-50.0,cloudcover:100.0,pop:28.6,datetime:1658750400000,precip:0.0,solarradiation:785.0, dew:68.3,humidity:61.2,temp:0,visibility:15.0,wspd:9.6,severerisk:10.0,solarenergy:2.8,heatindex:86.2,snowdepth:0.0,sealevelpressure:1014.0,snow:0.0,wgust:9.2, conditions:"Overcast",windchill:null,cape:52.0}, 
        {wdir:0,uvindex:8.0,icon:'--',datetimeStr:"2022-07-25T13:00:00+01:00",preciptype:"",cin:-22.0,cloudcover:100.0,pop:28.6,datetime:1658754000000,precip:0.0,solarradiation:771.0, dew:68.1,humidity:57.4,temp:0,visibility:15.0,wspd:10.3,severerisk:10.0,solarenergy:2.8,heatindex:88.2,snowdepth:0.0,sealevelpressure:1013.0,snow:0.0,wgust:9.4, conditions:"Overcast",windchill:null,cape:100.0}, 
        {wdir:0,uvindex:5.0,icon:'--',datetimeStr:"2022-07-25T14:00:00+01:00",preciptype:"",cin:-16.0,cloudcover:99.1,pop:14.3,datetime:1658757600000,precip:0.0,solarradiation:517.0, dew:68.3,humidity:59.4,temp:0,visibility:15.0,wspd:11.9,severerisk:10.0,solarenergy:1.9,heatindex:87.3,snowdepth:0.0,sealevelpressure:1013.0,snow:0.0,wgust:10.5, conditions:"Overcast",windchill:null,cape:134.0},
        {wdir:0,uvindex:4.0,icon:'--',datetimeStr:"2022-07-25T15:00:00+01:00",preciptype:"",cin:-45.0,cloudcover:56.3,pop:14.3,datetime:1658761200000,precip:0.0,solarradiation:435.0, dew:68.6,humidity:62.6,temp:0,visibility:15.0,wspd:13.2,severerisk:10.0,solarenergy:1.6,heatindex:86.0,snowdepth:0.0,sealevelpressure:1012.0,snow:0.0,wgust:11.9, conditions:"Partially rain",windchill:null,cape:109.0}, 
        {wdir:0,uvindex:5.0,icon:'--',datetimeStr:"2022-07-25T16:00:00+01:00",preciptype:"",cin:-55.0,cloudcover:98.7,pop:14.3,datetime:1658764800000,precip:0.0,solarradiation:538.0, dew:68.3,humidity:61.2,temp:0,visibility:15.0,wspd:13.2,severerisk:10.0,solarenergy:1.9,heatindex:86.2,snowdepth:0.0,sealevelpressure:1011.0,snow:0.0,wgust:12.3, conditions:"Overcast",windchill:null,cape:62.0}, 
        {wdir:0,uvindex:4.0,icon:'--',datetimeStr:"2022-07-25T17:00:00+01:00",preciptype:"",cin:-51.0,cloudcover:76.1,pop:14.3,datetime:1658768400000,precip:0.0,solarradiation:443.0, dew:68.3,humidity:61.9,temp:0,visibility:15.0,wspd:13.0,severerisk:10.0,solarenergy:1.6,heatindex:85.8,snowdepth:0.0,sealevelpressure:1011.0,snow:0.0,wgust:12.5, conditions:"Overcast",windchill:null,cape:53.0},
        {wdir:0,uvindex:3.0,icon:'--',datetimeStr:"2022-07-25T18:00:00+01:00",preciptype:"",cin:-83.0,cloudcover:98.8,pop:14.3,datetime:1658772000000,precip:0.0,solarradiation:250.0, dew:68.6,humidity:67.2,temp:0,visibility:15.0,wspd:12.3,severerisk:10.0,solarenergy:0.9,heatindex:83.4,snowdepth:0.0,sealevelpressure:1012.0,snow:0.0,wgust:12.8, conditions:"Overcast",windchill:null,cape:33.0}, 
        {wdir:0,uvindex:0.0,icon:'--',datetimeStr:"2022-07-25T19:00:00+01:00",preciptype:"",cin:-100.0,cloudcover:100.0,pop:14.3,datetime:1658775600000,precip:0.0,solarradiation:26.0, dew:68.8,humidity:70.5,temp:0,visibility:15.0,wspd:11.2,severerisk:10.0,solarenergy:0.1,heatindex:null,snowdepth:0.0,sealevelpressure:1012.0,snow:0.0,wgust:13.0, conditions:"Overcast",windchill:null,cape:23.0}, 
        {wdir:0,uvindex:0.0,icon:'--',datetimeStr:"2022-07-25T20:00:00+01:00",preciptype:"",cin:-122.0,cloudcover:100.0,pop:14.3,datetime:1658779200000,precip:0.0,solarradiation:0.0, dew:69.0,humidity:71.3,temp:0,visibility:15.0,wspd:11.0,severerisk:3.0,solarenergy:0.0,heatindex:null,snowdepth:0.0,sealevelpressure:1012.0,snow:0.0,wgust:13.6, conditions:"Overcast",windchill:null,cape:34.0}, 
        {wdir:0,uvindex:0.0,icon:'--',datetimeStr:"2022-07-25T21:00:00+01:00",preciptype:"",cin:-135.0,cloudcover:91.4,pop:14.3,datetime:1658782800000,precip:0.0,solarradiation:0.0, dew:69.3,humidity:73.1,temp:0,visibility:7.3,wspd:10.7,severerisk:3.0,solarenergy:0.0,heatindex:null,snowdepth:0.0,sealevelpressure:1013.0,snow:0.0,wgust:13.9, conditions:"Overcast",windchill:null,cape:28.0}, 
        {wdir:0,uvindex:0.0,icon:'--',datetimeStr:"2022-07-25T22:00:00+01:00",preciptype:"",cin:-102.0,cloudcover:100.0,pop:14.3,datetime:1658786400000,precip:0.01,solarradiation:0.0, dew:69.5,humidity:73.9,temp:0,visibility:15.0,wspd:10.3,severerisk:3.0,solarenergy:0.0,heatindex:null,snowdepth:0.0,sealevelpressure:1013.0,snow:0.0,wgust:13.4, conditions:"Overcast",windchill:null,cape:45.0}, 
        {wdir:0,uvindex:0.0,icon:'--',datetimeStr:"2022-07-25T23:00:00+01:00",preciptype:"",cin:-66.0,cloudcover:76.2,pop:14.3,datetime:1658790000000,precip:0.0,solarradiation:0.0, dew:69.7,humidity:74.4,temp:0,visibility:15.0,wspd:10.1,severerisk:10.0,solarenergy:0.0,heatindex:null,snowdepth:0.0,sealevelpressure:1013.0,snow:0.0,wgust:13.2, conditions:"Overcast",windchill:null,cape:39.0}, 
        {wdir:0,uvindex:0.0,icon:'--',datetimeStr:"2022-07-26T00:00:00+01:00",preciptype:"",cin:-43.0,cloudcover:39.9,pop:14.3,datetime:1658793600000,precip:0.0,solarradiation:0.0, dew:69.7,humidity:75.3,temp:0,visibility:15.0,wspd:9.6,severerisk:10.0,solarenergy:0.0,heatindex:null,snowdepth:0.0,sealevelpressure:1014.0,snow:0.0,wgust:12.3, conditions:"Partially rain",windchill:null,cape:42.0}, 
        {wdir:0,uvindex:0.0,icon:'--',datetimeStr:"2022-07-26T01:00:00+01:00",preciptype:"",cin:-27.0,cloudcover:73.4,pop:14.3,datetime:1658797200000,precip:0.0,solarradiation:0.0, dew:69.9,humidity:76.7,temp:0,visibility:15.0,wspd:9.2,severerisk:10.0,solarenergy:0.0,heatindex:null,snowdepth:0.0,sealevelpressure:1013.0,snow:0.0,wgust:13.4, conditions:"Partially rain",windchill:null,cape:40.0}, 
    ], 
    id:"lagos",address:"--",name:"lagos",index:0,latitude:6.4547,longitude:3.38877,distance:0.0,time:0.0,tz:"Africa/Lagos", 
    currentConditions: {wdir: 0.00,temp:0.00,sunrise:"2022-07-24T06:40:050+01:00",visibility:12.4,wspd:0,icon:"--",stations:"",heatindex:null,cloudcover:0.0,datetime:"2022-07-24T06:00:000+01:00", precip:null,moonphase:0.94,snowdepth:null,sealevelpressure:0.0,dew:68.8,sunset:"2022-07-24T19:05:540+01:00",humidity:0,wgust:null,windchill:null},
    alerts:null, 
 
}
    
}