import styled from "styled-components";


export const Container = styled.div`
    padding: 20px 50px 20px 50px ;
    @media screen and (max-width:800px){
        padding: 0px 20px 0px 20px
    }
`
export const ContainerBlur = styled.div`
    padding: 20px 20px 20px 20px ;
`